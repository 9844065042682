import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';
import { StocksApiService } from '@app/shared/api/stocks-api.service';
import { Stock } from '@models/stock';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-stock-select',
    templateUrl: './stock-select.component.html',
    styleUrls: ['./stock-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: StockSelectComponent,
            multi: true,
        },
    ],
})
export class StockSelectComponent implements OnInit, ControlValueAccessor {
    @Input() required = false;

    inputControl: FormControl<string | null> = new FormControl();
    onChange: Function;
    filteredOptions: Observable<Stock[]>;
    options: Stock[];
    limit: number = 100;

    constructor(private stocksApiService: StocksApiService) {}

    async ngOnInit() {
        if (this.limit > 0) {
            this.options = (await this.stocksApiService.list(0, this.limit)).items;
        } else {
            this.options = await this.stocksApiService.getAllStocks();
        }

        const validators = [];

        validators.push((control: AbstractControl): ValidationErrors | null => {
            if (!this.options || !control.value) {
                return null;
            }

            const index = this.options
                .map((option: Stock) => option.name.toLowerCase())
                .indexOf((control.value.name ? control.value.name : control.value).toLowerCase());

            return index > -1
                ? null
                : { notFound: { value: control.value } };
        });

        this.inputControl.setValidators(validators);

        this.filteredOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            map(value => this._filter(value)),
        );
    }

    private _filter(value: string): Stock[] {
        const filterValue = (value || '').toLowerCase();
        return (this.options || []).filter(option => option.name.toLowerCase().includes(filterValue));
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(value: Stock): void {
        if (!value) {
            return;
        }
        this.inputControl.setValue(value.name);
    }

    onOptionSelect($event) {
        this.writeValue($event.option.value);

        if (this.onChange) {
            this.onChange($event.option.value);
        }
    }

    clear() {
        this.inputControl.setValue('');

        if (this.onChange) {
            this.onChange(null);
        }
    }
}
